import type { IconProps } from './IconsTypes'

type Props = {
  logged: boolean
} & IconProps

export const UpdatedUserNew = ({
  width = 26,
  height = 30,
  logged,
  className,
}: Props) => {
  return !logged ? (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill="none"
    >
      <path
        d="M8.00903 8.75C5.66503 8.75 3.75903 6.843 3.75903 4.5C3.75903 2.157 5.66503 0.25 8.00903 0.25C10.353 0.25 12.259 2.157 12.259 4.5C12.259 6.843 10.353 8.75 8.00903 8.75ZM8.00903 1.75C6.49203 1.75 5.25903 2.983 5.25903 4.5C5.25903 6.017 6.49203 7.25 8.00903 7.25C9.52603 7.25 10.759 6.017 10.759 4.5C10.759 2.983 9.52503 1.75 8.00903 1.75ZM11.9969 19.75H4.00305C1.58305 19.75 0.25 18.425 0.25 16.019C0.25 13.358 1.756 10.25 6 10.25H10C14.244 10.25 15.75 13.357 15.75 16.019C15.75 18.425 14.4169 19.75 11.9969 19.75ZM6 11.75C2.057 11.75 1.75 15.017 1.75 16.019C1.75 17.583 2.42405 18.25 4.00305 18.25H11.9969C13.5759 18.25 14.25 17.583 14.25 16.019C14.25 15.018 13.943 11.75 10 11.75H6Z"
        fill="black"
      />
    </svg>
  ) : (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill="none"
    >
      <path
        d="M4.00903 4.5C4.00903 2.294 5.80303 0.5 8.00903 0.5C10.215 0.5 12.009 2.294 12.009 4.5C12.009 6.706 10.215 8.5 8.00903 8.5C5.80303 8.5 4.00903 6.706 4.00903 4.5ZM10 10.5H6C1.94 10.5 0.5 13.473 0.5 16.019C0.5 18.296 1.71105 19.5 4.00305 19.5H11.9969C14.2889 19.5 15.5 18.296 15.5 16.019C15.5 13.473 14.06 10.5 10 10.5Z"
        fill="black"
      />
    </svg>
  )
}
