import clickCollectImg from 'src/images/click-and-collect.svg'
import noClickCollectImg from 'src/images/no-click-collect.svg'
import truckImg from 'src/images/truck.svg'

interface AvailabilityQuantityProps {
  sku: string
  max?: number
  min?: number
  addQuantity: number
  unitMultiplier?: number | null
  setAddQuantity?: React.Dispatch<React.SetStateAction<number>>
  clickAndCollect: boolean | null | undefined
}

const AvailabilityQuantityPDP = ({
  max,
  min = 1,
  addQuantity,
  setAddQuantity,
  unitMultiplier,
  clickAndCollect,
}: AvailabilityQuantityProps) => {
  const isLeftDisabled = addQuantity === min
  const isRightDisabled = addQuantity === max
  const quantityWithUnitMultiplier = unitMultiplier
    ? addQuantity * unitMultiplier
    : addQuantity

  const changeQuantity = (increaseValue: number) => {
    setAddQuantity?.(addQuantity + increaseValue)
  }

  const increase = () => changeQuantity(1)

  const decrease = () => changeQuantity(-1)
  return (
    <div className="px-4 md:px-6">
      <div className="flex items-end gap-6 text-sm">
        <div>
          <div className="mb-2 font-normal">Quantità</div>
          <div className="flex items-center h-10 quantity-container-js">
            <div className="hidden quantity-value-input" />
            <button
              data-testid="buy-box-decrease-quantity-btn"
              className={`h-full w-[38px] flex items-center justify-center border-2 border-black rounded-l-lg border-r-0 cursor-pointer quantity-trigger minus ${
                isLeftDisabled ? 'disabled' : ''
              }`}
              style={!isLeftDisabled ? { backgroundColor: 'white' } : {}}
              onClick={decrease}
            >
              <span className="block select-none">-</span>
            </button>
            <div className="h-full w-10 flex items-center justify-center border-2 border-black">
              <span
                className="block quantity-value-label select-none"
                data-testid="buy-box-quantity-value"
              >
                {quantityWithUnitMultiplier}
              </span>
            </div>
            <button
              data-testid="buy-box-increase-quantity-btn"
              className={`h-full w-[38px] flex items-center justify-center border-2 border-black rounded-r-lg border-l-0 cursor-pointer quantity-trigger plus ${
                isRightDisabled ? 'disabled' : ''
              }`}
              onClick={increase}
              style={!isRightDisabled ? { backgroundColor: 'white' } : {}}
            >
              <span className="block select-none">+</span>
            </button>
          </div>
        </div>

        <div className="flex flex-col gap-1 text-green-700">
          <div className="flex items-center gap-2">
            <span
              className="block h-4 w-4 overflow-hidden bg-center bg-no-repeat bg-contain"
              style={{
                backgroundImage: `url(${truckImg})`,
              }}
            />

            {max && Number(max) > 0 && (
              <span className="text-[10px] leading-[130%]">
                Disponibile per la spedizione
              </span>
            )}
          </div>
          <div className="flex items-center gap-2">
            {
              <>
                <span
                  className="block h-4 w-4 overflow-hidden bg-center bg-no-repeat bg-contain"
                  style={{
                    backgroundImage: `url(${
                      clickAndCollect ? clickCollectImg : noClickCollectImg
                    })`,
                  }}
                />
                <span
                  className={`text-[10px] leading-[130%] ${
                    clickAndCollect ? '' : 'text-[#E27A13]'
                  }`}
                >
                  {clickAndCollect
                    ? 'Disponibile "Ordine e ritira"'
                    : 'Non disponibile "Ordine e ritira"'}
                </span>
              </>
            }
          </div>
        </div>
      </div>
      {Number(max) === 0 ||
        (quantityWithUnitMultiplier === Number(max) && (
          <div className="mt-4 flex flex-row items-center gap-2 text-orange-600 max-quantity-limit-info">
            <div className="w-1 min-w-[4px] h-1 min-h-[4px] rounded-full bg-orange-600" />
            <p className="text-xs leading-[120%] justify-start">
              Non sono presenti abbastanza prodotti in magazzino per soddisfare
              la richiesta
            </p>
          </div>
        ))}

      {Number(max) === 3 && (
        <div className="mt-4 flex flex-row items-center gap-2 text-orange-600 max-quantity-limit-info">
          <div className="w-1 min-w-[4px] h-1 min-h-[4px] rounded-full bg-orange-600" />
          <p className="text-xs leading-[120%] justify-start">
            Sono rimasti solo <b>3 articoli</b> in magazzino. <br />
            Affrettati e completa il tuo acquisto!
          </p>
        </div>
      )}
    </div>
  )
}

export default AvailabilityQuantityPDP
