import { ReactNode, useEffect, useState } from 'react'

interface Props {
  children: any
  skeleton?: ReactNode
}

export function RenderClient({ skeleton, children, ...delegated }: Props) {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
    document.body.dataset.testReady = 'true'
  }, [])
  if (!hasMounted) {
    return skeleton ?? null
  }

  return <div {...delegated}>{children}</div>
}
