import { ArrowDown } from 'src/components/icons/ArrowDown'

export const mockMenu2 = [
  {
    id: 99,
    name: 'Piccoli Animali',
    code: 'piccolianimali',
    url: '/piccoli-animali',
    image: <ArrowDown />,
    children: [
      {
        id: 86,
        name: 'First column',
        url: '',
        image: '',
        isVisible: false,
        children: [
          {
            id: 87,
            name: 'Roditori',
            url: '/piccoli-animali/roditori',
            image: '',
            children: [
              {
                id: 88,
                name: 'Fieno',
                url: '/piccoli-animali/roditori/fieno',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Mangime',
                url: '/piccoli-animali/roditori/mangime',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Snack',
                url: '/piccoli-animali/roditori/snack',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Lettiere',
                url: '/piccoli-animali/roditori/lettiere',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Igiene',
                url: '/piccoli-animali/roditori/igiene',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Accessori',
                url: '/piccoli-animali/roditori/accessori',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Antiparassitari',
                url: '/piccoli-animali/roditori/antiparassitari-e-curativi/antiparassitari',
                image: '',
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 86,
        name: 'Second column',
        url: '',
        image: '',
        isVisible: false,
        children: [
          {
            id: 87,
            name: 'Uccelli',
            url: '/piccoli-animali/uccelli',
            image: '',
            children: [
              {
                id: 88,
                name: 'Accessori',
                url: '/piccoli-animali/uccelli/accessori',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Mangime',
                url: '/piccoli-animali/uccelli/mangime',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Sabbie',
                url: '/piccoli-animali/uccelli/sabbie',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Snack',
                url: '/piccoli-animali/uccelli/snack',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Antiparassitari',
                url: '/piccoli-animali/uccelli/antiparassitari-e-curativi/antiparassitari',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Alimenti complementari',
                url: '/piccoli-animali/uccelli/antiparassitari-e-curativi/integratori',
                image: '',
                children: [],
              },
            ],
          },
          {
            id: 87,
            name: 'Pesci',
            url: '/piccoli-animali/pesci',
            image: '',
            children: [
              {
                id: 88,
                name: 'Mangime',
                url: '/piccoli-animali/pesci/mangime',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Accessori',
                url: '/piccoli-animali/pesci/accessori',
                image: '',
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 86,
        name: 'Third column',
        url: '',
        image: '',
        isVisible: false,
        children: [
          {
            id: 87,
            name: 'Tartarughe',
            url: '/piccoli-animali/tartarughe',
            image: '',
            children: [
              {
                id: 88,
                name: 'Mangime',
                url: '/piccoli-animali/tartarughe/mangime',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Accessori',
                url: '/piccoli-animali/tartarughe/accessori',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Medicali e curativi',
                url: '/piccoli-animali/tartarughe/antiparassitari-e-curativi',
                image: '',
                children: [],
              },
            ],
          },
          {
            id: 87,
            name: 'Rettili',
            url: '/piccoli-animali/rettili',
            image: '',
            children: [
              {
                id: 88,
                name: 'Mangime',
                url: '/piccoli-animali/rettili/mangime',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Accessori',
                url: '/piccoli-animali/rettili/accessori',
                image: '',
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 99,
    name: 'Persona e casa',
    code: 'personaecasa',
    url: '/persona-e-casa',
    image: <ArrowDown />,
    children: [],
  },
  {
    id: 527,
    name: 'Brand',
    url: '/brand',
    image: <ArrowDown />,
    children: [],
  },
  {
    id: 106,
    name: 'Magazine',
    url: 'https://magazine.arcaplanet.it/?_ga=2.43985760.43289048.1664203052-1077544268.1645182631&_gac=1.91036776.1663225788.Cj0KCQjwmouZBhDSARIsALYcouq0htiM3RG37IACnzGP_a-gHIwliiyJYSAQgpglOLcA93Qfvbrz1EUaAmHDEALw_wcB',
    image: <ArrowDown />,
    children: [],
  },
  // {
  //   id: 99,
  //   name: 'Arcacard',
  //   code: 'arcacard',
  //   url: 'https://magazine.arcaplanet.it/nuova-arcacard-ogni-acquisto-ha-piu-valore/',
  //   image: <ArrowDown />,
  //   children: [
  //     {
  //       id: 86,
  //       name: 'Il programma',
  //       url: 'https://magazine.arcaplanet.it/nuova-arcacard-ogni-acquisto-ha-piu-valore/',
  //       image: '',
  //       children: [
  //         {
  //           id: 87,
  //           name: 'Gli status Arcacard',
  //           url: '',
  //           image: '',
  //           children: [],
  //         },
  //         {
  //           id: 87,
  //           name: 'La raccolta punti',
  //           url: '',
  //           image: '',
  //           children: [],
  //         },
  //         {
  //           id: 87,
  //           name: 'I vantaggi',
  //           url: '',
  //           image: '',
  //           children: [],
  //         },
  //         {
  //           id: 87,
  //           name: 'Più valore ai tuoi punti',
  //           url: '',
  //           image: '',
  //           children: [],
  //         },
  //         {
  //           id: 87,
  //           name: 'Regolamento',
  //           url: '',
  //           image: '',
  //           children: [],
  //         },
  //       ],
  //     },
  //     {
  //       id: 86,
  //       name: 'Storie di cuccioli',
  //       url: 'https://magazine.arcaplanet.it/storie-di-cuccioli-il-programma-dedicato-ai-clienti-arcacard/',
  //       image: '',
  //       children: [],
  //     },
  //     {
  //       id: 86,
  //       name: 'Promozioni dedicate',
  //       url: '',
  //       image: '',
  //       children: [
  //         {
  //           id: 87,
  //           name: 'Promozioni Arcacard',
  //           url: '',
  //           image: '',
  //           children: [],
  //         },
  //         {
  //           id: 87,
  //           name: 'Servizi esclusivi',
  //           url: '',
  //           image: '',
  //           children: [],
  //         },
  //       ],
  //     },
  //     {
  //       id: 86,
  //       name: 'Registrati subito',
  //       url: '',
  //       image: '',
  //       children: [],
  //     },
  //   ],
  // },
  {
    id: 99,
    name: 'Promozioni',
    code: 'promozione',
    image: <ArrowDown />,
    children: [
      {
        id: 86,
        name: 'First column',
        url: '',
        image: '',
        isVisible: false,
        children: [
          {
            id: 87,
            name: 'Promozioni online',
            url: '/promozioni-online',
            image: '',
            children: [
              {
                id: 88,
                name: 'Imperdibili',
                url: '/offerte-imperdibili',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Eccellenze',
                url: '/eccellenze',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Novità',
                url: '/novita',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Speciale Multipack',
                url: '/speciale-multipack-per-cani-e-gatti',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Speciale Antiparassitari',
                url: '/antiparassitari-per-cani-e-gatti',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Speciale Tiragraffi',
                url: '/migliori-tiragraffi-per-gatti',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Prodotti fino a 2€',
                url: '/prodotti-fino-a-2-euro-in-esclusiva-da-arcaplanet',
                image: '',
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 86,
        name: 'Second column',
        url: '',
        image: '',
        isVisible: false,
        children: [
          {
            id: 87,
            name: 'Promozioni negozio',
            url: '/promozioni-negozio',
            image: '',
            children: [
              {
                id: 88,
                name: 'Volantino del mese',
                url: '/volantino-mese',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Programma Diete leggere',
                url: 'https://www.arcaplanet.it/programmadieteleggere',
                image: '',
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 86,
        name: 'Third column',
        url: '',
        image: '',
        isVisible: false,
        children: [
          {
            id: 87,
            name: 'Prezzi ribassati',
            url: '/prezzi-ribassati-in-esclusiva-da-arcaplanet',
            image: '',
            children: [
              {
                id: 88,
                name: 'Volantino prezzi ribassati',
                url: '/volantino-prezzi-ribassati',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Prodotti ribassati',
                url: '/prezzi-ribassati',
                image: '',
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
]
