export interface FavouriteStore {
  sellerId?: string
  id: string
  name: string
  postalCode: string
  geoCoordinates?: Array<number>
  vtexSellerId?: string
}

export type StoreData = {
  id: string
  street: string
  city: string
  region: string
  postalCode: string
  slowTime: string
  fastTime: string
  sellerId: string
  vtexSellerId: string
  hours: any
  icon: JSX.Element
  distance: number
  latitude: number
  longitude: number
  slowDate: string
  fastDate: string
  geoCoordinates?: Array<number>
}

export type FavouriteStorePickingTime = {
  fastTime?: string | null
  slowTime?: string | null
  hours?: any
  slowDate?: string | null
  fastDate?: string | null
  shippingEstimateCase?: ShippingEstimateCaseEnum
  notShippableProducts?: Array<string>
}

export type DeliveryEstimateRange = {
  type: DeliveryEstimateCaseEnum.Range
  value: { from: string; to: string }
} | null

export type DeliveryEstimateDate = {
  type: DeliveryEstimateCaseEnum.Date
  value: string
} | null

export type SkuAvailability = {
  sku: string
  isAvailable: boolean
}

export type ShippingEstimateCaseProps = {
  fastTime: any
  slowDate: any
  fastDate: any
  slowTime?: any
}

export type ShippingEstimateData = {
  shippingEstimateCaseProps: ShippingEstimateCaseProps
  notShippableProducts: Array<string>
} | null

export enum ShippingEstimateCaseEnum {
  Default,
  FastTimeMinLimitHours,
  FastTimeMoreThanMinLimitHours,
  FastTimeBeforeOpen,
  FastTimeAfterOpen,
  SlowTime,
}

export enum DeliveryEstimateCaseEnum {
  Range,
  Date,
}
