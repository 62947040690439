import { Suspense, memo, useState } from 'react'
import { useModal } from 'src/sdk/ui/modal/useModal'

import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { RenderClient } from 'src/components/RenderClient'
import { GoogleMapsProvider } from 'src/contexts/google-maps-context'
import { StoreModalProvider } from 'src/contexts/store-modal-context'
import { StoreInfo } from '../Store/StoreInfo'
import { StoreModal } from '../Store/StoreModal'
import AddToCartAlertPDP from './AddToCartAlertPDP'
import AvailabilityQuantityPDP from './AvailabilityQuantityPDP'
import BuyButtonPDP from './BuyButtonPDP'
import InfoDiscount from './InfoDiscount'
import ProgressiveDiscountPDP from './ProgressiveDiscountPDP'
import { Shipping } from './Shipping'
import SubscriptionButtonPDP from './SubscriptionButtonPDP'
import SubscriptionFrequencyPDP from './SubscriptionFrequencyPDP'
import VariantPDP from './VariantPDP'
import type { DefaultSku, ExtendedProductDetails, PriceInfo } from './typings'
import { orderFixedPrices } from './utilities'
import { PayLaterMessage } from '../Paypal/PayLaterMessage'

type PurchaseProps = {
  product: ExtendedProductDetails
  priceInfo: PriceInfo
  openAddToCart: boolean
  handleOpenCloseAddToCart: (x: boolean) => void
  setIsAddedFromSticky: React.Dispatch<React.SetStateAction<boolean>>
  isAddedFromSticky: boolean
  privateLabelMoltiplicator: number
}

const Purchase = ({
  product,
  priceInfo,
  openAddToCart,
  handleOpenCloseAddToCart,
  setIsAddedFromSticky,
  isAddedFromSticky,
  privateLabelMoltiplicator,
}: PurchaseProps) => {
  const { isMobile } = useBreakpoint()
  const [addQuantity, setAddQuantity] = useState(1)
  const [openSubscriptionModal, setOpenSubscriptionModal] =
    useState<boolean>(false)

  const [isSubscription, setIsSubscription] = useState(false)
  const {
    openProgressiveInfo,
    handleOpenCloseProgressiveInfo,
    openStoreInfo,
    handleOpenStoreInfo,
  } = useModal()

  const availableQuantity =
    product?.availableProducts && product?.availableProducts.availableQuantity

  const hasSubscription =
    !!product.stockKeepingUnit?.attachments?.subscriptions?.length

  const availableItems = Number(availableQuantity)

  const progressiveDiscountData = product.fixedPrices?.filter(
    (el) => el?.tradePolicyId === '1'
  )

  const isProgressiveDiscount: boolean =
    progressiveDiscountData != null && progressiveDiscountData.length > 0

  const defaultSku: DefaultSku = {
    sku: product.sku,
    availability:
      product.offers.offers[0].availability !== 'https://schema.org/OutOfStock',
    name: product.name,
    url: product.image[0].url,
    hasMoreThanOneVariant: product.isVariantOf.hasVariant.length > 1,
  }

  const isKit = product.stockKeepingUnit?.isKit

  return (
    <div className="md:h-full md:absolute md:right-0 md:top-0 md:max-w-[calc((100vw/12)*5-40px)] xl:max-w-[calc((100vw/12)*3.5-40px)] xl:w-[100%] 3xl:w-[calc((1520px/12)*3.5-20px)] md:pb-8 md:w-[100%]">
      <div className="relative md:h-[calc(100%-12px)] md:w-full md:pt-15 add-to-cart-col-JS">
        <div className="md:sticky md:top-10 md:pr-10">
          <div className="flex flex-col gap-6 md:py-6 md:rounded-lg md:shadow-[0px_0px_9px_0px_#DEDEDE]">
            <VariantPDP
              defaultSku={defaultSku}
              variations={product.isVariantOf.hasVariant}
            />
            <AvailabilityQuantityPDP
              sku={product.sku}
              unitMultiplier={product.productSpecifications?.unitMultiplier}
              min={1}
              addQuantity={addQuantity}
              max={availableItems}
              setAddQuantity={setAddQuantity}
              clickAndCollect={product.stockKeepingUnit?.clickAndCollect}
            />

            {progressiveDiscountData && progressiveDiscountData.length > 0 && (
              <ProgressiveDiscountPDP
                fixedPrices={orderFixedPrices(progressiveDiscountData)}
                listPrice={priceInfo.verifiedPriceToShow}
                addQuantity={addQuantity}
                unitMultiplier={product.productSpecifications?.unitMultiplier}
                setAddQuantity={setAddQuantity}
                handleOpenCloseProgressiveInfo={handleOpenCloseProgressiveInfo}
              />
            )}
            <div className="px-4 md:px-6 mt-2">
              <PayLaterMessage price={priceInfo.verifiedPriceToShow} />
              <div className="flex flex-col gap-6">
                <Suspense fallback={null}>
                  <RenderClient>
                    {isMobile && (
                      <Shipping
                        sku={product.sku}
                        quantity={addQuantity}
                        handleOpenStoreInfo={handleOpenStoreInfo}
                        isKit={isKit}
                      />
                    )}
                  </RenderClient>
                </Suspense>

                <BuyButtonPDP
                  fixedPrices={orderFixedPrices(progressiveDiscountData)}
                  priceInfo={priceInfo}
                  product={product}
                  quantity={addQuantity}
                  handleOpenCloseAddToCart={handleOpenCloseAddToCart}
                  isProgressiveDiscount={isProgressiveDiscount}
                />

                {hasSubscription && (
                  <SubscriptionButtonPDP
                    setOpenSubscriptionModal={setOpenSubscriptionModal}
                  />
                )}

                <Suspense fallback={null}>
                  <RenderClient>
                    {!isMobile && (
                      <Shipping
                        sku={product.sku}
                        quantity={addQuantity}
                        handleOpenStoreInfo={handleOpenStoreInfo}
                        isKit={isKit}
                      />
                    )}
                  </RenderClient>
                </Suspense>
              </div>
            </div>
          </div>
        </div>
        <AddToCartAlertPDP
          product={product}
          priceInfo={priceInfo}
          fixedPrices={progressiveDiscountData}
          quantity={addQuantity}
          openAddToCart={openAddToCart}
          handleOpenCloseAddToCart={handleOpenCloseAddToCart}
          isSubscription={isSubscription}
          setIsSubscription={setIsSubscription}
          setIsAddedFromSticky={setIsAddedFromSticky}
          isAddedFromSticky={isAddedFromSticky}
          privateLabelMoltiplicator={privateLabelMoltiplicator}
        />

        {hasSubscription && (
          <SubscriptionFrequencyPDP
            product={product}
            fixedPrices={orderFixedPrices(progressiveDiscountData)}
            priceInfo={priceInfo}
            quantity={addQuantity}
            openSubscriptionModal={openSubscriptionModal}
            handleOpenCloseAddToCart={handleOpenCloseAddToCart}
            setOpenSubscriptionModal={setOpenSubscriptionModal}
            setIsSubscription={setIsSubscription}
          />
        )}

        <InfoDiscount
          openProgressiveInfo={openProgressiveInfo}
          handleOpenCloseProgressiveInfo={handleOpenCloseProgressiveInfo}
        />
      </div>
      <StoreInfo
        openStoreInfo={openStoreInfo}
        handleOpenStoreInfo={handleOpenStoreInfo}
      />

      <GoogleMapsProvider>
        <StoreModalProvider>
          <StoreModal sku={product.sku} quantity={addQuantity} />
        </StoreModalProvider>
      </GoogleMapsProvider>
    </div>
  )
}

export default memo(Purchase)
