import { useEffect, useRef, useState } from 'react'
import StoreIcon from 'src/components/icons/StoreIcon'
import { XIcon } from 'src/components/icons/XIcon'
import { useStoreContext } from 'src/contexts/store-context'
import {
  FavouriteStorePickingTime,
  ShippingEstimateCaseEnum,
  ShippingEstimateCaseProps,
} from '../Store/typings'
import {
  formatPickingTime,
  getFormattedSingleDigit,
  getShippingEstimateCase,
  roundUpToNextQuarterHour,
  toItalianLocaleDateString,
} from '../Store/utilities'
import { usePickingEstimate } from 'src/sdk/store/usePickingEstimate'
import ChangeStore from '../Store/ChangeStore'
import { GreenCheck } from 'src/components/icons/GreenCheck'

type Props = {
  sku: string
  quantity: number
  handleOpenStoreInfo: (a: boolean) => void
}

export const PickingEstimate = ({
  sku,
  quantity,
  handleOpenStoreInfo,
}: Props) => {
  const [showStoreData, setShowStoreData] = useState<boolean>(false)
  const isMounted = useRef(false)
  const { getPickingEstimateByProduct } = usePickingEstimate()

  const {
    favouriteStore,
    pickingTime,
    setPickingTime,
    handleOpenStoreModal,
    setShowEmailInfoToast,
  } = useStoreContext()

  useEffect(() => {
    if (favouriteStore?.id) {
      setShowStoreData(false)
      getPickingEstimateByProduct(sku, quantity).then(
        (res: FavouriteStorePickingTime | null) => {
          if (res) {
            setPickingTime(res)
          }
          setShowStoreData(true)
        }
      )
    } else {
      setShowStoreData(true)
    }
  }, [favouriteStore?.id])

  useEffect(() => {
    let delayedPickingTime: any
    if (favouriteStore?.id && isMounted.current) {
      delayedPickingTime = setTimeout(() => {
        let emailTimeout: any
        setShowStoreData(false)
        getPickingEstimateByProduct(sku, quantity).then(
          (res: FavouriteStorePickingTime | null) => {
            if (res) {
              setPickingTime(res)
              setShowEmailInfoToast(true)

              emailTimeout = setTimeout(() => {
                setShowEmailInfoToast(false)
              }, 4000)
            }

            setShowStoreData(true)
          }
        )
        return clearTimeout(emailTimeout)
      }, 2000)
    } else {
      isMounted.current = true
    }

    return () => {
      clearTimeout(delayedPickingTime)
    }
  }, [quantity])

  const getShippingEstimateCaseProps: ShippingEstimateCaseProps = {
    fastTime: pickingTime?.fastTime,
    fastDate: pickingTime?.fastDate,
    slowDate: pickingTime?.slowDate,
  }
  const shippingEstimateCase = favouriteStore?.id
    ? getShippingEstimateCase(getShippingEstimateCaseProps)
    : ShippingEstimateCaseEnum.Default

  const shippingEstimateDateLocale = pickingTime?.fastTime
    ? roundUpToNextQuarterHour(new Date(getShippingEstimateCaseProps.fastDate))
    : roundUpToNextQuarterHour(new Date(getShippingEstimateCaseProps.slowDate))

  const isFavouriteStoreFranchise =
    favouriteStore?.vtexSellerId?.includes('Franchise')

  const openStoreModal = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault()
    handleOpenStoreModal(true)
  }

  const openStoreInfo = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault()
    handleOpenStoreInfo(true)
  }

  return showStoreData ? (
    !favouriteStore?.id ? (
      <span className="flex flex-row gap-1 items-start">
        <StoreIcon />
        <div className="flex flex-col text-black">
          <div className="font-medium text-sm">
            Verifica disponibilità al ritiro in negozio
          </div>
          <div
            data-testid="select-store-buy-box"
            onClick={() => handleOpenStoreModal(true)}
            className="text-xs font-bold underline cursor-pointer"
          >
            Scegli negozio
          </div>
        </div>
      </span>
    ) : (
      <span className="flex flex-row gap-1">
        {favouriteStore && (pickingTime?.slowTime || pickingTime?.fastTime) ? (
          <>
            <span>
              <GreenCheck />
            </span>
            <span className="flex flex-col gap-1">
              <span className="text-black font-medium text-sm">
                {(() => {
                  switch (shippingEstimateCase) {
                    case ShippingEstimateCaseEnum.FastTimeMinLimitHours:
                      return (
                        <div className="text-[#0F6B34] text-sm font-normal">
                          Ritiro
                          <span
                            className="font-semibold text-sm"
                            data-testid="pdp-picking-time"
                            data-value={formatPickingTime(
                              pickingTime?.fastTime
                            )}
                          >
                            {' '}
                            GRATUITO{' '}
                          </span>
                          a partire da{' '}
                          <span className="text-[#1E7541] font-semibold">
                            {formatPickingTime(pickingTime?.fastTime)}
                          </span>
                          <span className="font-normal text-black">
                            {' '}
                            presso {favouriteStore?.name}
                          </span>
                        </div>
                      )
                    case ShippingEstimateCaseEnum.FastTimeMoreThanMinLimitHours:
                      return (
                        <div className="text-[#0F6B34] text-sm font-normal">
                          Ritiro
                          <span
                            className="font-semibold text-sm"
                            data-testid="pdp-picking-time"
                            data-value={`${shippingEstimateDateLocale.getHours()}:
                            ${getFormattedSingleDigit(
                              shippingEstimateDateLocale.getMinutes()
                            )}`}
                          >
                            {' '}
                            GRATUITO{' '}
                          </span>
                          <span className="text-[#1E7541] font-semibold">
                            dalle {shippingEstimateDateLocale.getHours()}:
                            {getFormattedSingleDigit(
                              shippingEstimateDateLocale.getMinutes()
                            )}
                          </span>
                          <span className="font-normal text-black">
                            {' '}
                            presso {favouriteStore?.name}
                          </span>
                        </div>
                      )
                    case ShippingEstimateCaseEnum.FastTimeAfterOpen:
                      return (
                        <div className="text-[#0F6B34] text-sm font-normal">
                          Ritiro
                          <span className="font-semibold text-sm">
                            {' '}
                            GRATUITO{' '}
                          </span>
                          <span
                            className="text-[#1E7541] font-semibold"
                            data-testid="pdp-picking-time"
                            data-value={`${shippingEstimateDateLocale.getHours()}:
                            ${getFormattedSingleDigit(
                              shippingEstimateDateLocale.getMinutes()
                            )}`}
                          >
                            a partire da domani alle{' '}
                            {shippingEstimateDateLocale.getHours()}:
                            {getFormattedSingleDigit(
                              shippingEstimateDateLocale.getMinutes()
                            )}
                          </span>
                          <span className="font-normal text-black">
                            {' '}
                            presso {favouriteStore?.name}
                          </span>
                        </div>
                      )
                    case ShippingEstimateCaseEnum.SlowTime:
                      return (
                        <div className="text-black text-sm font-normal">
                          Ritiro
                          <span className="font-semibold text-sm">
                            {' '}
                            GRATUITO{' '}
                          </span>
                          <span
                            className="text-black font-semibold"
                            data-value={toItalianLocaleDateString(
                              shippingEstimateDateLocale
                            )}
                            data-testid="pdp-picking-time"
                          >
                            a partire dal{' '}
                            {toItalianLocaleDateString(
                              shippingEstimateDateLocale
                            )}
                            {isFavouriteStoreFranchise && (
                              <>
                                {' '}
                                alle {shippingEstimateDateLocale.getHours()}:
                                {getFormattedSingleDigit(
                                  shippingEstimateDateLocale.getMinutes()
                                )}
                              </>
                            )}
                            <span className="font-normal text-black">
                              {' '}
                              presso {favouriteStore?.name}
                            </span>
                          </span>
                        </div>
                      )
                    default:
                      return (
                        <>
                          <XIcon />
                          <div
                            className="text-[#CE3435] text-sm font-normal"
                            data-testid="store-picking-not-available"
                          >
                            Non disponibile al ritiro in negozio
                          </div>
                        </>
                      )
                  }
                })()}{' '}
              </span>
              <ChangeStore
                handleOpenStoreInfo={openStoreInfo}
                handleOpenStoreModal={openStoreModal}
              />
            </span>
          </>
        ) : (
          <>
            <XIcon />
            <span className="text-[#CE3435] font-medium text-sm">
              Non disponibile per il ritiro in negozio{' '}
              <ChangeStore
                handleOpenStoreInfo={openStoreInfo}
                handleOpenStoreModal={openStoreModal}
              />
            </span>
          </>
        )}
      </span>
    )
  ) : (
    <div className="animate-pulse flex space-x-4">
      <div className="flex-1 space-y-4 py-1">
        <div className="h-4 bg-gray-500 rounded"></div>
        <div className="h-4 bg-gray-500 rounded col-span-2"></div>
        <div className="h-4 bg-gray-500 rounded hidden md:block"></div>
      </div>
    </div>
  )
}
