import axios from 'axios'

export const useUserAccountAPI = () => {
  const getIsVet = async (orderFormId: string) => {
    const { data } = await axios.post('/api/userAccount/getIsVet', {
      orderFormId: orderFormId,
    })

    return data
  }

  return {
    getIsVet,
  }
}
