import { gql } from '@faststore/graphql-utils'
import type { SearchState } from '@faststore/sdk'
import { SearchProvider, parseSearchState, useSession } from '@faststore/sdk'
import type {
  CatchAllPageQueryQuery,
  CatchAllPageQueryQueryVariables,
  ServerCollectionPageQueryQuery,
} from '@generated/graphql'
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect, useMemo } from 'react'
// import LazyRenderCMS from 'src/components/LazyRenderCMS'
import RenderCMS from 'src/components/RenderCMS'
import { RenderClient } from 'src/components/RenderClient'
import Breadcrumb from 'src/components/sections/Breadcrumb'
import ProductGallery from 'src/components/sections/ProductGallery'
import ScrollToTopButton from 'src/components/sections/ScrollToTopButton'
import { ITEMS_PER_PAGE } from 'src/constants'
import { useScrollEvent } from 'src/sdk/analytics/hooks/useScrollEvent'
import { useUserEvent } from 'src/sdk/analytics/hooks/useUserEvent'
import { applySearchState } from 'src/sdk/search/state'
import { mark } from 'src/sdk/tests/mark'
import 'src/styles/pages/plp.scss'
import { getPlpSections } from 'src/utils'
import getPromotionsPages from 'src/utils/getPromotionsPages'

export type Props = PageProps<
  CatchAllPageQueryQuery,
  CatchAllPageQueryQueryVariables,
  unknown,
  ServerCollectionPageQueryQuery
> & { slug: string }

const useSearchParams = (props: Props): SearchState => {
  const {
    location: { href, pathname },
    serverData: { collection },
  } = props

  const selectedFacets = collection?.meta.selectedFacets

  return useMemo(() => {
    const maybeState = href ? parseSearchState(new URL(href)) : null

    return {
      page: maybeState?.page ?? 0,
      base: maybeState?.base ?? pathname,
      selectedFacets:
        maybeState && maybeState.selectedFacets.length > 0
          ? maybeState.selectedFacets
          : selectedFacets ?? [],
      term: maybeState?.term ?? null,
      sort: maybeState?.sort ?? 'score_desc',
    }
  }, [href, pathname, selectedFacets])
}

function Page(props: Props) {
  const {
    data: { site, allCmsPlp },
    serverData: { collection },
    location: { pathname, search },
    slug,
  } = props

  const { locale } = useSession()
  const searchParams = useSearchParams(props)

  useEffect(() => {
    window.addEventListener('scroll', useScrollEvent)

    return () => {
      window.removeEventListener('scroll', useScrollEvent)
    }
  }, [])

  useUserEvent('Product List Page')

  // No data was found
  if (!collection) {
    return null
  }

  const title = collection?.seo.title ?? site?.siteMetadata?.title ?? ''

  // Filter searchString in order to prevent the canonical from having any searchParam besides page.

  const searchObj = new URLSearchParams(search)

  const currentPage = searchObj.get('page') ?? 0

  const searchString = Number(currentPage) >= 1 ? `?page=${currentPage}` : ''

  // Filter searchString in order to prevent the canonical from having any searchParam besides page.

  const canonical = `${site?.siteMetadata?.siteUrl}/${
    slug || collection?.slug
  }${searchString}`

  const { edges: allPlpNodes } = allCmsPlp

  const sections = getPlpSections({ allPlpNodes, collection })

  let itemListElement = collection?.breadcrumbList.itemListElement.map((c) => c)

  // Fix brand pages bradcrumb
  if (
    pathname.indexOf('brand/') !== -1 &&
    itemListElement[0].name !== 'Brand'
  ) {
    itemListElement.unshift({
      item: '/brand',
      name: 'Brand',
      position: 0,
    })

    itemListElement = itemListElement?.map((element, index) => ({
      ...element,
      item:
        index > 0
          ? `${itemListElement[index - 1]?.item}${element?.item}`
          : element?.item,
    }))
  }

  // Fix collection pages breadcrumb
  if (collection?.type === 'Collection' && !itemListElement[0]?.name) {
    itemListElement[0].name = title
  }

  return (
    <RenderClient>
      <SearchProvider
        onChange={applySearchState}
        itemsPerPage={ITEMS_PER_PAGE}
        {...searchParams}
      >
        {/* SEO */}
        <GatsbySeo
          title={title}
          titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
          description={collection?.seo?.description ?? ''}
          canonical={canonical}
          language={locale}
          openGraph={{
            type: 'website',
            title,
            description: collection?.seo?.description ?? '',
          }}
        />
        <BreadcrumbJsonLd itemListElements={itemListElement ?? []} />
        {/*
        WARNING: Do not import or render components from any
        other folder than '../components/sections' in here.
        This is necessary to keep the integration with the CMS
        easy and consistent, enabling the change and reorder
        of elements on this page.
        If needed, wrap your component in a <Section /> component
        (not the HTML tag) before rendering it here.
      */}
        <div className="flex flex-col gap-6">
          <Breadcrumb
            breadcrumbList={itemListElement}
            name={collection?.seo.title}
          />
          <section>
            {/* RenderCMS splices the first 3 elements for render without lazyloading */}
            <RenderCMS divider="before" sections={sections} />
            {/* LazyRenderCMS render all the elements with lazyloading unless the 3 first */}
            {/* <LazyRenderCMS divider="before" sections={sections} /> */}
          </section>

          <ProductGallery title={title} slug={slug} sections={sections} />

          <ScrollToTopButton text="Scorri verso l'alto" />
        </div>
      </SearchProvider>
    </RenderClient>
  )
}

export const querySSG = graphql`
  query CatchAllPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
        siteUrl
      }
    }
    allCmsPlp {
      edges {
        node {
          parameters {
            collection {
              seo {
                slug
              }
              brandId
              categoryId
              generic
              clusterId
            }
          }
          sections {
            name
            data
          }
        }
      }
    }
  }
`

export const querySSR = gql`
  query ServerCollectionPageQuery($slug: String!) {
    collection(slug: $slug) {
      seo {
        title
        description
      }
      breadcrumbList {
        itemListElement {
          item
          name
          position
        }
      }
      meta {
        selectedFacets {
          key
          value
        }
      }
      slug
      type
      id
    }
  }
`

export const getServerData = async ({
  params: { slug: uncheckedSlug },
}: {
  params: Record<string, string>
}) => {
  let slug: string =
    uncheckedSlug.indexOf('brand/') !== -1
      ? uncheckedSlug.replace('brand/', '')
      : uncheckedSlug

  let wasBrandCroppedFromSlug = false

  if (uncheckedSlug.indexOf('brand/') !== -1) {
    wasBrandCroppedFromSlug = true
    slug = uncheckedSlug.replace('brand/', '')
  } else {
    slug = uncheckedSlug
  }

  const { execute } = await import('src/server/index')
  let serverData

  try {
    const { data } = await execute({
      operationName: querySSR,
      variables: { slug },
    })

    if (data) {
      serverData = data
    } else {
      serverData = await getPromotionsPages(slug)
    }

    if (serverData === null) {
      return {
        status: 301,
        props: {},
        headers: {
          'cache-control': 'public, max-age=0, stale-while-revalidate=31536000',
          location: `/404`,
        },
      }
    }

    if (
      //   eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      serverData?.collection?.type?.toLowerCase() === 'brand' &&
      !wasBrandCroppedFromSlug
    ) {
      return {
        status: 301,
        props: {},
        headers: {
          'cache-control': 'public, max-age=0, stale-while-revalidate=31536000',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          location: `/brand/${serverData?.collection?.slug}`,
        },
      }
    }

    if (
      //   eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      serverData?.collection?.type?.toLowerCase() !== 'brand' &&
      wasBrandCroppedFromSlug
    ) {
      return {
        status: 301,
        props: {},
        headers: {
          'cache-control': 'public, max-age=0, stale-while-revalidate=31536000',
          location: `/404`,
        },
      }
    }

    return {
      status: 200,
      props: serverData,
      headers: {
        'cache-control': 'public, max-age=0, stale-while-revalidate=31536000',
      },
    }
  } catch (err) {
    console.error(err)

    return {
      status: 500,
      props: {},
      headers: {
        'cache-control': 'public, max-age=0, must-revalidate',
      },
    }
  }
}

Page.displayName = 'Page'
export default mark(Page)
