import type { IconProps } from './IconsTypes'

export const ArrowDown = ({
  width = 40,
  height = 40,
  viewBox = '0 0 24 24',
  color = '#000',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ display: 'block', verticalAlign: 'middle' }}
  >
    <path
      d="m15.29 9.77-3.53 3.52-3.53-3.52"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
