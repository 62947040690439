import { QuantitySelector as UIQuantitySelector } from '@faststore/ui'
import { memo, useEffect, useState } from 'react'
import Icon from 'src/components/ui/Icon'
import './quantity-selector.scss'

interface QuantitySelectorProps {
  max?: number
  min?: number
  initial?: number
  disabled?: boolean
  unitMultiplier?: number | null
  onChange: (value: number) => void
}

export function QuantitySelector({
  max,
  min = 1,
  initial,
  disabled = false,
  onChange,
  unitMultiplier,
}: QuantitySelectorProps) {
  const [quantity, setQuantity] = useState<number>(initial ?? min)
  const isLeftDisabled = quantity === min
  const isRightDisabled = quantity === max
  const quantityWithUnitMultiplier = unitMultiplier
    ? quantity * unitMultiplier
    : quantity

  useEffect(() => {
    if (max && quantity > max) {
      setQuantity(max)
    }
  }, [max, quantity])

  const changeQuantity = (increaseValue: number) => {
    const quantityValue = validateQuantityBounds(quantity + increaseValue)

    onChange(quantityValue)
    setQuantity(quantityValue)
  }

  const increase = () => changeQuantity(1)

  const decrease = () => changeQuantity(-1)

  function validateQuantityBounds(n: number): number {
    const maxValue = min ? Math.max(n, min) : n

    return max ? Math.min(maxValue, max) : maxValue
  }

  function validateInput(e: React.FormEvent<HTMLInputElement>) {
    const val = e.currentTarget.value

    if (!Number.isNaN(Number(val))) {
      setQuantity(() => {
        const quantityValue = validateQuantityBounds(Number(val))

        onChange(quantityValue)

        return quantityValue
      })
    }
  }

  useEffect(() => {
    initial && setQuantity(initial)
  }, [initial])

  return (
    <UIQuantitySelector
      data-fs-quantity-selector={disabled ? 'disabled' : 'true'}
      quantity={quantityWithUnitMultiplier}
      leftButtonProps={{
        testId: 'decrease-quantity-from-cart',
        onClick: decrease,
        disabled: isLeftDisabled || disabled,
        icon: <Icon name="Minus" width={12} height={12} weight="bold" />,
      }}
      rightButtonProps={{
        testId: 'increase-quantity-from-cart',
        onClick: increase,
        disabled: isRightDisabled || disabled,
        icon: <Icon name="Plus" width={12} height={12} weight="bold" />,
      }}
      inputProps={{
        testId: 'cart-item-quantity',
        onChange: validateInput,
        readOnly: true,
        disabled,
      }}
    />
  )
}

export default memo(QuantitySelector)
