import { Card, CardActions, CardContent } from '@faststore/ui'
import { useEffect, useState } from 'react'
import { TrashIcon } from 'src/components/icons/TrashIcon'
import type { AdditionalProperty } from 'src/components/sections/NewPDP/typings'
import { translateFrequencies } from 'src/components/sections/NewPDP/utilities'
// import { DiscountBadge } from 'src/components/ui/Badge'
import Button from 'src/components/ui/Button'
import { Image } from 'src/components/ui/Image'
import Price from 'src/components/ui/Price'
import QuantitySelector from 'src/components/ui/QuantitySelector'
// import { useProductSpecifications } from 'src/hooks/useProductSpecifications'
import { useRemoveButton } from 'src/sdk/cart/useRemoveButton'
import { useUpdateQuantity } from 'src/sdk/cart/useUpdateQuantity'
import type { CartItem as ICartItem } from 'src/sdk/cart/validate'
import { useFormattedPrice } from 'src/sdk/product/useFormattedPrice'
import './cart-item.scss'
import CartItemLoyaltyPoints from './CartItemLoyaltyPoints'

interface Props {
  item: ICartItem
  isCartValidating: boolean
  setIsVTEXValidating: any
}

function CartItem({ item, isCartValidating, setIsVTEXValidating }: Props) {
  const isKit = item?.itemOffered?.stockKeepingUnit?.isKit
  const { onClick: onClickRemove, ...btnProps } = useRemoveButton(
    { ...item, isKit },
    setIsVTEXValidating
  )

  const itemSku = item?.itemOffered?.sku

  const itemVariantFiltered = item?.itemOffered?.isVariantOf?.hasVariant.filter(
    (i) => i.sku === itemSku
  )

  const itemPrice = itemVariantFiltered[0]?.offers?.lowPrice

  const { updateItemQuantity, error } = useUpdateQuantity(
    { ...item, isKit },
    setIsVTEXValidating
  )

  const { listPrice, price } = item
  const totalPriceByItem = price * item.quantity
  const [hasStock, setHasStock] = useState(Boolean)

  // const productSpecifications = useProductSpecifications(item.itemOffered.sku)

  const outOfStockCard = hasStock ? '' : 'outOfStockCard'

  const subscriptions = item?.itemOffered?.additionalProperty?.filter(
    (el: AdditionalProperty) =>
      el.valueReference === 'ATTACHMENT' &&
      el.name.includes('vtex.subscription')
  )

  const discount = hasStock
    ? Math.trunc(100 - (itemPrice * 100) / listPrice)
    : Math.trunc(100 - (price * 100) / listPrice)

  const isZeroOrLess = discount <= 0

  // let isPrivateLabel: boolean
  // const skuAttributes =
  //   item?.itemOffered?.isVariantOf?.additionalProperty?.find(
  //     (el) => el.name === 'Arcaplanet SKU Attributes'
  //   )?.value

  // try {
  //   isPrivateLabel =
  //     JSON.parse(skuAttributes)[item?.itemOffered?.sku]?.private_label ===
  //     'true'
  // } catch (e) {
  //   isPrivateLabel = false
  // }

  // const privateLabelMoltiplicator = isPrivateLabel ? 2 : 1
  const privateLabelMoltiplicator = 1
  const loyaltyPoints =
    subscriptions?.length > 0
      ? Math.trunc(totalPriceByItem * 0.95) * privateLabelMoltiplicator
      : Math.trunc(totalPriceByItem) * privateLabelMoltiplicator

  useEffect(() => {
    setHasStock(itemPrice > 0)
  }, [itemPrice])

  return (
    <Card
      className={`cart-item ${outOfStockCard}`}
      data-testid="cart-item"
      data-sku={item?.itemOffered?.sku}
      data-seller={item?.seller?.identifier}
    >
      <CardContent>
        {error && (
          <div className="error">
            <p>{error}</p>
          </div>
        )}
        <div className="top">
          <div className="itemImage__container" style={{ display: 'block' }}>
            <Image
              baseUrl={item.itemOffered.image?.[0].url}
              alt={item.itemOffered.image?.[0].alternateName}
              sourceWidth={360}
              aspectRatio={1}
              width={55}
              breakpoints={[50, 100, 150]}
              loading="eager"
              layout="constrained"
              backgroundColor="#f0f0f0"
              options={{
                fitIn: true,
              }}
              imgStyle={{ display: 'block' }}
              style={{ display: 'block' }}
            />
          </div>
          <div data-cart-item-summary>
            <div>
              <p className="text__body" data-cart-item-title>
                {item.itemOffered.isVariantOf.name}
              </p>
            </div>
            <div className="item-loyalty-points-wrapper">
              <CartItemLoyaltyPoints loyaltyPoints={loyaltyPoints} />
            </div>
            <span data-cart-item-prices>
              {/* {!isZeroOrLess && (
                <DiscountBadge
                  listPrice={listPrice}
                  spotPrice={hasStock ? itemPrice : price}
                  big
                />
              )} */}
              {!isZeroOrLess && (
                <Price
                  value={listPrice}
                  formatter={useFormattedPrice}
                  testId="list-price"
                  data-value={listPrice}
                  variant="installment"
                  classes="text__legend"
                  SRText="Original price:"
                />
              )}
              <Price
                value={hasStock ? itemPrice : price}
                formatter={useFormattedPrice}
                testId="price"
                data-value={hasStock ? itemPrice : price}
                variant="spot"
                classes="text__title-subsection"
                SRText="Price:"
              />
            </span>
          </div>
          <Button
            variant="tertiary"
            icon={
              <TrashIcon
                width={28}
                height={26}
                color="#14181F"
                viewBox="0 0 28 26"
              />
            }
            iconPosition="left"
            {...btnProps}
            className="top__icon"
            onClick={isCartValidating ? undefined : onClickRemove}
          />
        </div>

        {!hasStock && (
          <div className="outofStock__message-container">
            <p>
              Questo articolo non è al momento disponibile.
              <br />
              <strong className="strongText-out">
                Rimuovi questo articolo
              </strong>{' '}
              per procedere <br />
              <span>all’acquisto.</span>
            </p>
          </div>
        )}

        <div className="bottom">
          <CardActions>
            <QuantitySelector
              min={1}
              initial={item.quantity}
              // unitMultiplier={productSpecifications.unitMultiplier}
              unitMultiplier={
                item.itemOffered?.productSpecifications?.unitMultiplier
              }
              onChange={(quantity) => {
                updateItemQuantity(quantity)
              }}
              disabled={isCartValidating}
            />
          </CardActions>
          <Price
            value={totalPriceByItem}
            formatter={useFormattedPrice}
            testId="price"
            data-value={totalPriceByItem}
            variant="spot"
            classes="text__title-subsection"
            SRText="Price:"
          />
        </div>

        {subscriptions?.length > 0 && (
          <div className="subscription-tag">
            <span className="arca-ico-autoship subscription-icon-minicart">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
            </span>
            <span className="subscription-tag-title">Ordine Periodico</span>
            <span className="subscription-tag-frequency">Frequenza:</span>
            <span className="subscription-tag-frequency-value">
              {translateFrequencies(
                subscriptions[0]?.value['vtex.subscription.key.frequency']
              )}
            </span>
          </div>
        )}
      </CardContent>
    </Card>
  )
}

export default CartItem
