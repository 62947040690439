import { useCart } from '@faststore/sdk'
import { FixedPrice } from '@generated/graphql'
import axios from 'axios'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useBuyButtonPDP } from 'src/sdk/cart/useBuyButtonPDP'
import {
  AdditionalProperty,
  ExtendedProductDetails,
  PriceInfo,
} from './typings'
import {
  blockOutsideScroll,
  clearRadioInputList,
  getFixedPricesToShow,
  translateFrequencies,
} from './utilities'

type SubscriptionFrequencyProp = {
  product: ExtendedProductDetails
  priceInfo: PriceInfo
  fixedPrices: Array<FixedPrice | null> | null | undefined
  quantity: number
  openSubscriptionModal: boolean
  handleOpenCloseAddToCart: (x: boolean) => void
  setOpenSubscriptionModal: React.Dispatch<React.SetStateAction<boolean>>
  setIsSubscription: React.Dispatch<React.SetStateAction<boolean>>
}

function SubscriptionFrequencyPDP({
  product,
  priceInfo,
  fixedPrices,
  quantity,
  openSubscriptionModal,
  setOpenSubscriptionModal,
  handleOpenCloseAddToCart,
  setIsSubscription,
}: SubscriptionFrequencyProp) {
  const [attachment, setAttachment] = useState<AdditionalProperty | null>()
  const ref = useRef<HTMLDivElement>(null)

  const [disableSetFrequencyBtn, setDisableSetFrequencyBtn] = useState(true)
  const [hasAddedSubscriptionItem, setHasAddedSubscriptionItem] =
    useState<boolean>(false)

  const {
    isValidating: isCartValidating,
    addItem,
    removeItem,
    ...cart
  } = useCart()

  const subscriptions = useMemo(() => {
    let result: Array<{ index: number; name: string; frequency: string }> = []
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    product?.stockKeepingUnit?.attachments?.subscriptions?.forEach(
      (el: any) => {
        let frequencies = el.fields[0].domainValues.split(',')
        frequencies.forEach((freq: string, index: number) => {
          result.push({
            index: index,
            name: el.name,
            frequency: freq,
          })
        })
      }
    )

    return result
  }, [product])

  const additionalProperty = JSON.parse(
    JSON.stringify(product.additionalProperty)
  )
  additionalProperty.push(attachment)

  const isKit = product.stockKeepingUnit?.isKit

  const item = {
    id: product.id,
    price: product?.offers?.offers[0]?.price,
    listPrice: product?.offers?.offers[0]?.listPrice,
    seller: product?.offers?.offers[0]?.seller,
    quantity: quantity,
    isKit,
    itemOffered: {
      sku: product.sku,
      name: product.name,
      breadcrumbList: product.breadcrumbList,
      gtin: product.gtin,
      image: product.image,
      brand: product.brand,
      isVariantOf: product.isVariantOf,
      additionalProperty: additionalProperty,
    },
  }

  const { discount: fixedDiscount } = getFixedPricesToShow(
    fixedPrices,
    quantity,
    priceInfo.verifiedUnitMultiplier,
    priceInfo.verifiedListPriceToShow
  )

  const buyProps = useBuyButtonPDP({
    item,
    boxType: 'buy box',
    boxDiscount: fixedDiscount,
    recurringOrder: true,
  })

  const handleClearSubscriptionAlert = () => {
    setOpenSubscriptionModal(false)
    blockOutsideScroll(false)
    setAttachment(null)
    setDisableSetFrequencyBtn(true)
    clearRadioInputList(ref.current)
  }

  const handleSelectFrequency = (freq: string, name: string) => {
    setAttachment({
      name,
      value: JSON.parse(`{ "vtex.subscription.key.frequency": "${freq}" }`),
      propertyID: '',
      valueReference: 'ATTACHMENT',
    })

    setDisableSetFrequencyBtn(false)
  }

  const handleSetFrequency = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsSubscription(true)
    buyProps.onClick(e)
    setHasAddedSubscriptionItem(true)
    handleClearSubscriptionAlert()
    handleOpenCloseAddToCart(true)
  }

  const sendSubscriptionData = async () => {
    await axios.post('/api/subscriptions/addSubscriptionData', {
      cart,
    })

    addItem(item)
    removeItem(item.id)
  }

  useEffect(() => {
    if (hasAddedSubscriptionItem && !isCartValidating) {
      sendSubscriptionData()
      setHasAddedSubscriptionItem(false)
    }
  }, [isCartValidating])

  return (
    <div
      id="set-frequenza-autoship"
      className={`modal-lightbox ${openSubscriptionModal ? 'open' : ''}`}
    >
      <div
        className="lightbox"
        style={{ display: openSubscriptionModal ? 'block' : 'none' }}
      >
        <div className="mx-auto md:rounded-lg bg-white">
          <div id="imposta-autoship-prodotto" className="tabs-panel active">
            <div className="pt-6 relative">
              <div className="flex px-6 justify-between items-start gap-2 mb-7">
                <div>
                  <div className="flex items-center gap-2 mb-3">
                    <span className="arca-ico-autoship block text-lg !leading-[19px]">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                      <span className="path4" />
                    </span>
                    <p className="text-lg font-normal">Frequenza di consegna</p>
                  </div>
                  <p className="text-xs md:text-sm">
                    <b>Risparmia tempo</b> e <b>fatica</b> con i nostri ordini
                    programmabili!
                    <br />
                    <b>Seleziona</b> la frequenza con la quale vuoi riceve{' '}
                    <b>il tuo prodotto</b>.
                  </p>
                </div>
                <button
                  style={{ cursor: 'pointer', backgroundColor: 'white' }}
                  className="block"
                  data-close=""
                  onClick={handleClearSubscriptionAlert}
                >
                  <span className="arca-ico-close text-2xl mt-1" />
                </button>
              </div>
              <div>
                <div
                  className="px-6 h-screen md:h-[unset] overflow-auto"
                  ref={ref}
                >
                  {subscriptions.map(
                    (
                      el: { index: number; name: string; frequency: string },
                      index: number
                    ) => {
                      return (
                        <div
                          key={`subs-freq-${index}`}
                          className="flex items-center gap-3 py-3 text-sm border-b border-[#F2F4F5] cursor-pointer"
                        >
                          <input
                            id={`autoship-freq-${index}`}
                            type="radio"
                            name="autoship-freq"
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              handleSelectFrequency(el.frequency, el.name)
                            }
                          />{' '}
                          <label>{translateFrequencies(el.frequency)}</label>
                        </div>
                      )
                    }
                  )}
                </div>
                <div className="fixed md:relative bottom-0 md:py-8 left-0 w-full bg-white rounded-b-lg border-t-2 md:border-none border-[#F2F4F5] shadow-[0px_-2px_15px_rgba(0,0,0,0.15)] px-6 pt-6 pb-8 z-[60]">
                  <button
                    className="btn btn btn-autoship py-[10px] w-full cursor-pointer"
                    data-tab="acquista-prodotti-interessanti"
                    data-open=""
                    onClick={(e) => handleSetFrequency(e)}
                    disabled={disableSetFrequencyBtn}
                  >
                    <div className="flex justify-center items-center gap-3">
                      <div className="bg-white rounded-full h-[19px] w-[19px] flex items-center justify-center">
                        <span className="arca-ico-autoship block text-lg !leading-[19px]">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                          <span className="path4" />
                        </span>
                      </div>
                      <span className="block text-left">Imposta frequenza</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className={`modal-overlay ${
          openSubscriptionModal ? 'block opacity-100' : 'hidden opacity-0'
        }`}
        onClick={handleClearSubscriptionAlert}
      />
    </div>
  )
}

export default SubscriptionFrequencyPDP
