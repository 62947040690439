import ClerkioShelf from '../Clerkio/ClerkioShelf'
import DescriptionPDP from './DescriptionPDP'
import DosagePDP from './DosagePDP'
import IngredientsPDP from './IngredientsPDP'
import ReviewsPDP from './ReviewsPDP'
import type { ExtendedProductDetails } from './typings'

type AttributesProps = {
  product: ExtendedProductDetails
}

function AttributesPDP({ product }: AttributesProps) {
  let ingredients: string | null = null

  const fixedStructuredData = product.structuredData ?? ''

  try {
    ingredients = JSON.parse(fixedStructuredData)[product.sku]?.Ingredienti
  } catch (e) {
    ingredients = null
  }

  const dosage = product?.isVariantOf?.additionalProperty?.find(
    (el) => el.name === 'Dosaggio'
  )?.value

  return (
    <>
      <div className="border-t-4 border-[#DDDFE1] xl:mt-44 3xl:mt-24 3xl:-mr-[25%] 3xl:-ml-[25%]" />
      <div>
        <ClerkioShelf
          productLogic="products"
          content={product.isVariantOf.productGroupID}
          dataTemplate="@pdp-slide-1"
          isModal={false}
          showCarousel={true}
        />

        <DescriptionPDP description={product.description} />

        {(ingredients || dosage) && (
          <div className="w-full max-w-[1520px] mx-auto">
            <div className="px-4 md:px-10 pt-6 md:pt-10">
              {ingredients && <IngredientsPDP ingredients={ingredients} />}
              {dosage && <DosagePDP dosage={dosage} />}
            </div>
          </div>
        )}

        <ClerkioShelf
          productLogic="products"
          content={product.isVariantOf.productGroupID}
          dataTemplate="@pdp-slide-2"
          isModal={false}
          showCarousel={true}
        />

        <ReviewsPDP
          gtin={product.gtin}
          productName={product.isVariantOf.name}
        />
      </div>
    </>
  )
}

export default AttributesPDP
