import { useSession } from '@faststore/sdk'
import type { HomePageQueryQuery } from '@generated/graphql'
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo'
import { useEffect } from 'react'
// import LazyRenderCMS from 'src/components/LazyRenderCMS'
import RenderCMS from 'src/components/RenderCMS'
import { useScrollEvent } from 'src/sdk/analytics/hooks/useScrollEvent'
import { useUserEvent } from 'src/sdk/analytics/hooks/useUserEvent'
// import RenderPageSections from 'src/components/cms/RenderPageSections'
import { mark } from 'src/sdk/tests/mark'
import 'src/styles/pages/homepage.scss'

export type Props = PageProps<HomePageQueryQuery>

function Page(props: Props) {
  const {
    data: { site, cmsHome },
  } = props

  const { locale } = useSession()

  const title = site?.siteMetadata?.title ?? ''
  const siteUrl = `${site?.siteMetadata?.siteUrl}`

  useUserEvent('Homepage')

  useEffect(() => {
    // const mainParentElement = document.getElementById('main-parent-element')

    // if (mainParentElement != null) {
    //   mainParentElement.style.maxWidth = '1600px'
    // }

    window.addEventListener('scroll', useScrollEvent)

    return () => {
      window.removeEventListener('scroll', useScrollEvent)
    }
  }, [])

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={title}
        description={site?.siteMetadata?.description ?? ''}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        language={locale}
        canonical={siteUrl}
        openGraph={{
          type: 'website',
          url: siteUrl,
          title: title ?? '',
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <JsonLd
        json={{
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          url: siteUrl,
          potentialAction: {
            '@type': 'SearchAction',
            target: `${siteUrl}/s/?q={search_term_string}`,
            'query-input': 'required name=search_term_string',
          },
        }}
      />
      {/*
        WARNING: Do not import or render components from any
        other folder than '../components/sections' in here.

        This is necessary to keep the integration with the CMS
        easy and consistent, enabling the change and reorder
        of elements on this page.

        If needed, wrap your component in a <Section /> component
        (not the HTML tag) before rendering it here.
      */}
      <div className="default__renderLoadHome">
        {/* RenderCMS splices the first 3 elements for render without lazyloading */}
        <RenderCMS sections={cmsHome?.sections} />
        {/* LazyRenderCMS render all the elements with lazyloading unless the 3 first */}
        {/* <LazyRenderCMS sections={cmsHome?.sections} /> */}
      </div>
    </>
  )
}

export const querySSG = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
        description
        titleTemplate
        siteUrl
      }
    }
    cmsHome {
      sections {
        name
        data
        id
      }
    }
  }
`

Page.displayName = 'Page'
export default mark(Page)
