import { CheckCircle } from 'src/components/icons/CheckCircle'
import { StoreEmailInfoToastIcon } from 'src/components/icons/StoreEmailInfoToastIcon'
import { StoreTruck } from 'src/components/icons/StoreTruck'
import { useStoreContext } from 'src/contexts/store-context'
import { DeliveryEstimate } from './DeliveryEstimate'
import { PickingEstimate } from './PickingEstimate'
import ReturnsLabel from './ReturnsLabel'

type Props = {
  sku: string
  quantity: number
  handleOpenStoreInfo: (a: boolean) => void
  isKit: boolean | null | undefined
}

export const Shipping = ({
  sku,
  quantity,
  handleOpenStoreInfo,
  isKit,
}: Props) => {
  const { showSavedStoreToast, showEmailInfoToast } = useStoreContext()

  return (
    <>
      <div className="flex flex-col gap-3">
        {isKit ? (
          <>
            <ReturnsLabel />
            <hr className="h-1 bg-[#f2f4f5]" />
          </>
        ) : null}
        <div className="flex flex-col gap-1">
          <div className="flex flex-row gap-1">
            <span>
              <StoreTruck />
            </span>
            <div className="text-black font-semibold text-base">
              Consegna e ritiro
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <DeliveryEstimate sku={sku} />
            <PickingEstimate
              sku={sku}
              quantity={quantity}
              handleOpenStoreInfo={handleOpenStoreInfo}
            />
          </div>
        </div>
      </div>
      {showEmailInfoToast && (
        <span className="block md:hidden">
          <div className="absolute w-[calc(100%-32px)] bg-[#E3EEFF] rounded-[5px] mt-2 px-4 py-2 flex flex-row gap-2 items-center">
            <StoreEmailInfoToastIcon />
            <div className="text-sm leading-none text-[#143669]">
              Attendere conferma via email del tempo di ritiro
            </div>
          </div>
        </span>
      )}
      {showSavedStoreToast && (
        <span className="block md:hidden">
          <div className="absolute w-[calc(100%-32px)] bg-[#0F6B34] rounded-[5px] mt-4 px-4 py-2 flex flex-row gap-2 items-center">
            <CheckCircle color="white" />
            <div className="text-sm leading-none text-white">
              Store preferito salvato
            </div>
          </div>
        </span>
      )}
    </>
  )
}
