/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import type { SearchState } from '@faststore/sdk'
import { SearchProvider, parseSearchState, useSession } from '@faststore/sdk'
import type {
  LandingPageQueryQuery,
  LandingPageQueryQueryVariables,
} from '@generated/graphql'
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import { useCallback, useEffect, useMemo } from 'react'
// import LazyRenderCMS from 'src/components/LazyRenderCMS'
import RenderCMS from 'src/components/RenderCMS'
import { RenderClient } from 'src/components/RenderClient'
import Breadcrumb from 'src/components/sections/Breadcrumb'
import ProductGallery from 'src/components/sections/ProductGallery'
import { ITEMS_PER_PAGE } from 'src/constants'
import { useScrollEvent } from 'src/sdk/analytics/hooks/useScrollEvent'
import { applySearchState } from 'src/sdk/search/state'
import { mark } from 'src/sdk/tests/mark'

type Props = PageProps<
  LandingPageQueryQuery,
  LandingPageQueryQueryVariables,
  unknown
> & { slug: string }

const useSearchParams = (props: Props, facets?: any): SearchState => {
  const {
    location: { href, pathname },
  } = props

  const selectedFacets = facets

  return useMemo(() => {
    const maybeState = href ? parseSearchState(new URL(href)) : null

    return {
      page: maybeState?.page ?? 0,
      base: maybeState?.base ?? pathname,
      selectedFacets:
        maybeState && maybeState.selectedFacets.length > 0
          ? maybeState.selectedFacets
          : selectedFacets ?? [],
      term: maybeState?.term ?? null,
      sort: maybeState?.sort ?? 'score_desc',
    }
  }, [href, pathname, selectedFacets])
}

interface ItemListElement {
  item: string
  name: string
  position: number
}
const getItemListElement = (
  elements: Array<{ item: string; name: string }>
): ItemListElement[] => {
  return elements?.map((e, index: number) => ({
    item: e?.item,
    name: e?.name,
    position: index + 1,
  }))
}

function convertFacets(facet: string | undefined | null, index: number) {
  let keyFacet = facet?.replace(' ', '-').toLocaleLowerCase() ?? ''

  if (facet === 'categoria') {
    keyFacet = `category-${index + 1}`
  }

  return keyFacet
}

function Page(props: Props) {
  const {
    data: { site, allCmsInstitutionalPage },
    pageContext,
  } = props

  const { locale } = useSession()
  const { edges } = allCmsInstitutionalPage
  const path = pageContext.pagePath
  const [cmsData] = edges
    .filter(
      (item: any) => item?.node?.config?.slugAndFilterConfig?.slug === path
    )
    ?.map((e: any) => e?.node)

  const selectedFacets: Array<{ key: string; value: string }> = []
  const filterCMS = cmsData?.config?.slugAndFilterConfig?.filterGroup?.allItems

  if (filterCMS && filterCMS?.length > 0) {
    cmsData?.config?.slugAndFilterConfig?.filterGroup?.allItems?.map(
      (item: any, index: any) =>
        selectedFacets.push({
          key: convertFacets(item?.key, index),
          value:
            item?.value
              ?.replaceAll(' ', '-')
              .replaceAll('+', '-')
              .replaceAll('&', '-')
              .toLocaleLowerCase() ?? '',
        })
    )
  }

  const searchParams = useSearchParams(props, selectedFacets)

  const { base } = searchParams

  const filterTitle = cmsData?.seo?.siteMetadataWithSlug?.title
  const filterDescription = cmsData?.seo?.siteMetadataWithSlug?.description
  const seoSlug = cmsData?.seo?.siteMetadataWithSlug?.slug

  const title = site?.siteMetadata?.title ?? ''
  const pageTitle = cmsData?.config?.slugAndFilterConfig?.pageTitle ?? title
  const canonical = `${site?.siteMetadata?.siteUrl}${seoSlug}`
  const breadcrumbName =
    cmsData?.config?.slugAndFilterConfig?.pageTitle ??
    filterTitle?.replaceAll('| Arcaplanet', '')

  const itemListElements = getItemListElement([
    { item: path, name: breadcrumbName },
  ])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    if (params?.facets?.length) {
      document.getElementById('productgrid')?.scrollIntoView()
    }
  }, [selectedFacets])

  useEffect(() => {
    window.addEventListener('scroll', useScrollEvent)

    return () => {
      window.removeEventListener('scroll', useScrollEvent)
    }
  }, [])

  const productGalleryProps = {
    term: null,
    base,
    title: pageTitle,
  }

  const ref = useCallback((node: any) => {
    if (!node) {
      return
    }

    const cbid = 'b2208671-252a-45ce-b3a4-1450f91c0d69'
    const script = document.createElement('script')

    script.id = 'CookieDeclaration'
    script.type = 'text/javascript'
    script.async = true
    script.src = `https://consent.cookiebot.com/${cbid}/cd.js`
    node.appendChild(script)
  }, [])

  return (
    <RenderClient>
      <SearchProvider
        onChange={applySearchState}
        itemsPerPage={ITEMS_PER_PAGE}
        {...searchParams}
      >
        {/* SEO */}
        <GatsbySeo
          title={filterTitle ?? ''}
          titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
          description={filterDescription ?? ''}
          canonical={canonical}
          language={locale}
          openGraph={{
            type: 'website',
            title,
            description: filterDescription ?? '',
          }}
        />
        <BreadcrumbJsonLd itemListElements={itemListElements ?? []} />
        <div className="flex flex-col gap-6">
          <Breadcrumb name={breadcrumbName} />
          {/* RenderCMS splices the first 3 elements for render without lazyloading */}

          <section className="md:px-10">
            <RenderCMS sections={cmsData?.sections} />
          </section>
          {/* LazyRenderCMS render all the elements with lazyloading unless the 3 first */}
          {/* <LazyRenderCMS sections={cmsData?.sections} /> */}
          {(path === '/cookie-declaration' || path === '/cookies-policy') && (
            <div ref={ref} id="CookiebotDeclaration" />
          )}

          {selectedFacets.length > 0 ? (
            <>
              <ProductGallery {...productGalleryProps} />
            </>
          ) : null}
        </div>
      </SearchProvider>
    </RenderClient>
  )
}

/**
 * This query is run during SSG
 * */
export const querySSG = graphql`
  query LandingPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
        siteUrl
      }
    }
    allCmsInstitutionalPage {
      edges {
        node {
          name
          sections {
            data
            name
          }
          seo {
            siteMetadataWithSlug {
              slug
              description
              title
              titleTemplate
            }
          }
          config {
            slugAndFilterConfig {
              slug
              pageTitle
              filterGroup {
                allItems {
                  key
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`

Page.displayName = 'Page'
export default mark(Page)
