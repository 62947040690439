import { useState } from 'react'
import { blockOutsideScroll } from 'src/components/sections/NewPDP/utilities'

export function useModal() {
  const [openAddToCart, setOpenAddToCart] = useState<boolean>(false)
  const [openProgressiveInfo, setOpenProgressiveInfo] = useState<boolean>(false)
  const [openStoreInfo, setOpenStoreInfo] = useState<boolean>(false)
  const [openStoreModal, setOpenStoreModal] = useState<boolean>(false)
  const [openSearchFilterModal, setOpenSearchFilterModal] =
    useState<boolean>(false)

  const handleOpenCloseAddToCart = (isOpened: boolean) => {
    setOpenAddToCart(!openAddToCart)
    blockOutsideScroll(isOpened)
  }

  const handleOpenCloseProgressiveInfo = (isOpened: boolean) => {
    setOpenProgressiveInfo(!openProgressiveInfo)
    blockOutsideScroll(isOpened)
  }

  const handleOpenStoreInfo = (isOpened: boolean) => {
    setOpenStoreInfo(!openStoreInfo)
    blockOutsideScroll(isOpened)
  }

  const handleOpenStoreModal = (isOpened: boolean) => {
    setOpenStoreModal(!openStoreModal)
    blockOutsideScroll(isOpened)
  }

  const handleOpenSearchFilterModal = (isOpened: boolean) => {
    setOpenSearchFilterModal(!openSearchFilterModal)
    blockOutsideScroll(isOpened)
  }

  return {
    openAddToCart,
    openProgressiveInfo,
    openStoreInfo,
    openStoreModal,
    openSearchFilterModal,
    handleOpenCloseAddToCart,
    handleOpenCloseProgressiveInfo,
    handleOpenStoreInfo,
    handleOpenStoreModal,
    handleOpenSearchFilterModal,
  }
}
