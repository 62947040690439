import { useSession } from '@faststore/sdk'
import type { FC } from 'react'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useUserAccountAPI } from 'src/sdk/userAccount/useUserAccountAPI'
import {
  getSessionIsVet,
  saveIsVetInSession,
} from 'src/components/sections/UserAccount/utilities'
import { useCart } from 'src/sdk/cart/useCart'

export type UserAccountContext = {
  isVet: boolean | null
  setIsVet: (a: boolean) => void
}

const UserAccountContext = createContext({} as UserAccountContext)
export const UserAccountProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [isVet, setIsVet] = useState<boolean | null>(null)

  const { person } = useSession()
  const { id } = useCart()

  const { getIsVet } = useUserAccountAPI()

  useEffect(() => {
    const sessionIsVet = getSessionIsVet()

    saveIsVetInSession(null)

    if (person?.email && sessionIsVet) {
      setIsVet(sessionIsVet)
      saveIsVetInSession(sessionIsVet)
    } else {
      person?.email &&
        id &&
        getIsVet(id).then((data: any) => {
          if (data) {
            setIsVet(data)
            saveIsVetInSession(data)
          }
        })
    }
  }, [person?.email, id])

  const value = {
    isVet,
    setIsVet,
  }

  return (
    <UserAccountContext.Provider value={value}>
      {children}
    </UserAccountContext.Provider>
  )
}

export const useUserAccountContext = () => {
  return useContext(UserAccountContext)
}
