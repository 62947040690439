import { Fragment, useState } from 'react'
import { ArrowRightMenuCustom } from 'src/components/icons/ArrowRightMenuCustom'
import { ArrowleftIcons } from 'src/components/icons/ArrowleftIcons'
import { XCloseCustom } from 'src/components/icons/XCloseCustom'
import Link from 'src/components/ui/Link'
import SlideOver from 'src/components/ui/SlideOver'
import { useModal } from 'src/sdk/ui/modal/Provider'

import menu from '../MainModal/mock/menu'
import { formatedCategory } from '../MainModal/utils/index'
import type { ImageMenuProps, ItemMenuProps } from '../typings/menu'
import MenuLevelX from './MenuLevelX'
import './secondmodal.scss'

interface Props {
  categoryFirstmodal: string
  closeModal: (a: boolean) => void
  submenu: []
}
interface PropsFirstmodal {
  index: number
  title?: string
  iconName?: string
}

const SecondaryModal = ({ categoryFirstmodal, closeModal }: Props) => {
  const category = formatedCategory(categoryFirstmodal)
  const menuContentMobile = menu[category][0].items
  const imageContetMobile = menu[category][0].images

  const { onModalClose } = useModal()
  const [showMenu, setShowMenu] = useState(false)
  const [secondCategoy, setSecondCategoy] = useState({
    index: 0,
    title: '',
  })

  const handleCloseSlideOver = () => {
    onModalClose()
    setShowMenu(false)
  }

  const handleClick = ({
    index,
    title = '',
    iconName = '',
  }: PropsFirstmodal) => {
    setSecondCategoy({ index, title })
    iconName === '' ? onModalClose() : setShowMenu(true)

    if (!menuContentMobile[index].items) {
      handleCloseSlideOver()
      window.location.href = menuContentMobile[index].link
    }
  }

  const closeModalSecondary = () => {
    setShowMenu(false)
  }

  if (showMenu && secondCategoy.title) {
    const submenu = menuContentMobile[secondCategoy.index]
      .items as ItemMenuProps[]

    const submenuImages = menuContentMobile[secondCategoy.index]
      .images as ImageMenuProps[]

    return (
      <SlideOver
        isOpen={showMenu}
        onDismiss={handleCloseSlideOver}
        size="full"
        direction="leftSide"
        className="modal__mobile"
      >
        <MenuLevelX
          submenu={submenu}
          submenuImages={submenuImages}
          categoryName={secondCategoy.title}
          closeModal={closeModalSecondary}
        />
      </SlideOver>
    )
  }

  return (
    <>
      <div className="secondarymodal__header">
        <div className="secondarymodal__header--content">
          <div className="secondarymodal__header--icontitle">
            <button
              className="mainmodal__nav--button svg.arrow-left width-btn-arrow-left"
              onClick={() => closeModal(false)}
              style={{ width: 'fit-content' }}
            >
              <ArrowleftIcons width={20} height={20} />
            </button>
            <span>{categoryFirstmodal}</span>
          </div>
          <div
            className="menu-close-modal-container"
            onClick={() => {
              handleCloseSlideOver()
            }}
            aria-hidden="true"
          >
            <XCloseCustom />
          </div>{' '}
        </div>
      </div>
      <>
        {menuContentMobile?.map(
          ({ title, iconName, subtitle, items }: ItemMenuProps, index) => (
            <div className="mainmodal__nav--wrapper" key={index}>
              <div className="mainmodal__nav--button" data-mainmodal={title}>
                <Fragment key={index}>
                  <button
                    className="mainmodal__nav--iconlink"
                    onClick={() => handleClick({ index, title, iconName })}
                  >
                    {/* {iconName?.length ? (
                      <img
                        src={`/menuMobileImage/${iconName}`}
                        alt={iconName}
                        width={24}
                        height={24}
                      />
                    ) : null} */}
                    <button
                      onClick={() => handleCloseSlideOver()}
                      className="mainmodal__nav--title"
                    >
                      {title}
                      <span className="subtitle">{subtitle}</span>
                    </button>
                  </button>
                  <button
                    key={index}
                    className="mainmodal__nav--button-arrow"
                    onClick={() => handleClick({ index, title, iconName })}
                  >
                    {((items && items.length > 0) ||
                      title.includes('Visualizza')) && (
                      <div className="arrowRightModal-container">
                        <ArrowRightMenuCustom />
                      </div>
                    )}
                  </button>
                </Fragment>
              </div>
              <hr className="mainmodal__nav--hr" />
            </div>
          )
        )}
      </>
      <div className="secondarymodal__footer">
        {imageContetMobile?.map(({ img, alt, url }: ImageMenuProps, index) => (
          <Link
            className="secondarymodal__footer--url"
            href={url}
            as="a"
            key={index}
            onClick={() => handleCloseSlideOver()}
          >
            <img
              className="secondarymodal__footer--img"
              src={`/menuMobileImage/${img}`}
              alt={alt}
            />
          </Link>
        ))}
      </div>
    </>
  )
}

export default SecondaryModal
