import {
  Icon,
  List as UIList,
  PaymentMethods as UIPaymentMethods,
} from '@faststore/ui'
import { RenderClient } from 'src/components/RenderClient'
import { ArcaplanetLogoFooterDesktop } from 'src/components/icons/ArcaplanetLogoFooterDesktop'
import { VtexIcon } from 'src/components/icons/VtexIcon'
import SROnly from 'src/components/ui/SROnly'
// import TrustPilotWidget from 'src/components/ui/TrustPilotWidget/TrustPilotWidget'
import { Suspense, lazy } from 'react'
import widgetSettings from 'src/components/ui/TrustPilotWidget/widgetSettings'
import { mark } from 'src/sdk/tests/mark'

import { legaltext, paymentIcons, promotions, services } from './FooterData'
import FooterLinks from './FooterLinks'

import './footer.scss'

const TrustPilotWidget = lazy(
  () => import('src/components/ui/TrustPilotWidget/TrustPilotWidget')
)

function Footer() {
  return (
    <footer className="footer layout__content-full">
      <div className="logofooter justify-items-center bg-black">
        <ArcaplanetLogoFooterDesktop />
        {/* <ArcaplanetLogoFooterMobile /> */}
      </div>

      <div className="title__footer">
        <p>Numeri 1 in Italia con oltre 580 negozi!</p>
      </div>

      <div className="footer__widget">
        <Suspense fallback={null}>
          <RenderClient>
            <TrustPilotWidget
              widgetSettings={widgetSettings.horizontalEssential}
            />
          </RenderClient>
        </Suspense>
      </div>

      <div className="footer__section ">
        <FooterLinks />
      </div>
      <div className="footer__section-desktop">
        <hr className="hr__footer" />
        <div className="footer__service">
          <h3 className="footer__service-title">I nostri servizi:</h3>
          <div className="footer__service-content">
            {services?.map((service, index) => (
              <li className="footer__icon-service" key={index}>
                <Icon component={service.name} />
                {service.href ? (
                  <a href={service?.href}>
                    <p>{service.text}</p>
                  </a>
                ) : (
                  <p>{service.text}</p>
                )}
              </li>
            ))}
          </div>
        </div>
        <div className="footer__promotion">
          <h3 className="footer__service-title">Promozioni:</h3>
          <div className="footer__service-content">
            {promotions?.map((promotion, index) => (
              <li className="footer__text-promotion" key={index}>
                {promotion.href ? (
                  <a href={promotion.href}>
                    <p>{promotion.text}</p>
                  </a>
                ) : (
                  <p>{promotion.text}</p>
                )}
              </li>
            ))}
          </div>
        </div>
      </div>

      <UIPaymentMethods>
        <div className="footer__note ">
          <div className="text__title-footer">{legaltext}</div>
          <UIList>
            <div className="footer__icon-section">
              <div className="logo__vtex">
                <VtexIcon />
              </div>
              <div className="payment__list">
                {paymentIcons?.map((paymentIcon, index) => (
                  <li key={index}>
                    {paymentIcon.name}
                    <SROnly text={paymentIcon.text} />
                  </li>
                ))}
              </div>
            </div>
          </UIList>
        </div>
      </UIPaymentMethods>
      <div className="footer-note__parent" />
    </footer>
  )
}

Footer.displayName = 'Footer'

export default mark(Footer)
