import { Label } from '@faststore/ui'
import { SkuFragmentFragment } from '@generated/graphql'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import Button from 'src/components/ui/Button'
import VariantPDP from './VariantPDP'
import { DefaultSku } from './typings'
import { useState } from 'react'

export interface OutOfStockProps {
  defaultSku: DefaultSku
  variations?: SkuFragmentFragment[]
  seller?: {
    identifier: string
  }
  price?: number
}

type FormData = {
  name: string
  email: string
}

async function submitAvailabilytyNotify(
  { defaultSku, seller, price }: OutOfStockProps,
  email: string,
  name: string
) {
  let result: any
  try {
    result = await axios.patch('/api/availabilityNotify', {
      skuId: defaultSku.sku,
      name,
      email,
      createdAt: new Date().toISOString(),
      price,
      seller: seller?.identifier ?? '1',
    })
  } catch (err) {
    console.error(err)
  }

  return result
}

function OutOfStockProduct({
  defaultSku,
  variations,
  seller,
  price,
}: OutOfStockProps) {
  const TOAST_DURATION = 6000
  const { register, handleSubmit } = useForm<FormData>()
  const [showSentEmail, setShowSentEmail] = useState(false)
  const [showErrorEmail, setShowErrorEmail] = useState(false)

  const onSubmit = handleSubmit(async (data: FormData) => {
    try {
      const result = await submitAvailabilytyNotify(
        { defaultSku, seller, price },
        data.email,
        data.name
      )

      result?.data?.success ? setShowSentEmail(true) : setShowErrorEmail(true)

      setTimeout(() => {
        setShowSentEmail(false)
        setShowErrorEmail(false)
      }, TOAST_DURATION)
    } catch (err) {
      console.error(err.message)
    }
  })

  return (
    <div className="md:h-full md:absolute md:right-0 md:top-0 md:max-w-[calc((100vw/12)*5-40px)] xl:max-w-[calc((100vw/12)*3.5-40px)] xl:w-[100%] 3xl:w-[calc((1520px/12)*3.5-20px)] md:pb-8 md:w-[100%]">
      <div className="relative md:h-[calc(100%-12px)] md:w-full md:pt-15 add-to-cart-col-JS">
        <div className="md:sticky md:top-10">
          <VariantPDP defaultSku={defaultSku} variations={variations} />
          <form onSubmit={onSubmit}>
            <div className="px-4 md:px-10 md:pl-0">
              <div className="flex flex-col gap-6">
                <p className="text-sm">
                  Spiacenti, il prodotto selezionato è&nbsp;
                  <span className="underline">attualmente non disponibile</span>
                  .&nbsp;
                  {defaultSku.hasMoreThanOneVariant && (
                    <b>Esplora altre varianti disponibili!</b>
                  )}
                  &nbsp;Per ricevere un avviso quando il prodotto tornerà in
                  stock, lascia il tuo nome e mail qui sotto. Ti contatteremo al
                  più presto.
                </p>
                <div className="flex items-center gap-2 text-[#CE3535]">
                  <div className="w-1 h-1 rounded-full bg-[#CE3535]"></div>
                  <p className="text-xs">Non disponibile per la spedizione</p>
                </div>
                <div className="flex flex-col gap-4">
                  <div className="static-label text-left">
                    <Label className="block pb-1 text-xs">Nome</Label>
                    <input
                      {...register('name', { required: true, minLength: 3 })}
                      placeholder="Inserisci minimo 3 caratteri"
                      className="!bg-white text-sm"
                    />
                  </div>
                  <div className="static-label text-left">
                    <Label className="block pb-1 text-xs">E-mail</Label>
                    <input
                      {...register('email', { required: true })}
                      type="email"
                      placeholder="La tua email"
                      className="!bg-white text-sm"
                    />
                  </div>
                  <div className="">
                    <Button type="submit" className="btn btn-primary w-full">
                      Avvisami quando è disponibile
                    </Button>
                  </div>
                  {showSentEmail && (
                    <div className="bg-[#CDFBE7] rounded-[5px] p-1.5 flex items-center">
                      <span className="arca-ico-check-circle" />
                      <div className="pl-1.5 text-xxs leading-none text-[#08683F]">
                        Abbiamo ricevuto la tua richiesta, grazie!
                      </div>
                    </div>
                  )}
                  {showErrorEmail && (
                    <div className="bg-[#EE2100] rounded-[5px] p-1.5 flex items-center">
                      <div className="pl-1.5 text-xxs leading-none text-[#ffffff]">
                        Si è presentato un errore, riprova più tardi, grazie!
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default OutOfStockProduct
