import { CartIcon } from 'src/components/icons/CartIcon'
import { useCart } from 'src/sdk/cart/useCart'
import { useCartToggleButton } from 'src/sdk/cart/useCartToggleButton'
import './cart-toggle.scss'

function CartToggle() {
  const btnProps = useCartToggleButton()
  const { totalUniqueItems, isValidating } = useCart()

  return (
    <div className="flex items-center justify-center">
      <button
        className="cursor-pointer bg-yellow px-0 flex"
        aria-label={`Cart with ${btnProps['data-items']} items`}
        disabled={isValidating}
        onClick={btnProps.onClick}
        data-testid={btnProps['data-testid']}
        data-items={btnProps['data-items']}
      >
        <CartIcon
          width={26}
          height={27}
          viewBox="0 0 32 32"
          color="black"
          className="chart-icon w-6 h-6 md:w-8 md:h-8"
        />
      </button>
      {totalUniqueItems > 0 && (
        <span
          data-testid=""
          className={`relative flex items-center justify-center w-[22px] h-[22px] px-1 py-0 rounded-[50%] ${
            isValidating
              ? 'animate-[spinner_1s_linear_infinite] border-t-white'
              : 'text-yellow bg-black text-xxs'
          } border-[3px] border-solid border-black right-2 bottom-[11px]`}
        >
          {!isValidating ? totalUniqueItems : ''}
        </span>
      )}
    </div>
  )
}

export default CartToggle
