import { useState } from 'react'
import CartToggle from 'src/components/cart/CartToggle'
import { ArcaplanetLogoBrand } from 'src/components/icons/ArcaplanetLogoBrand'
import { Ellipse } from 'src/components/icons/Ellipse'
import { HamburgerMenu } from 'src/components/icons/HamburguerMenu'
import { MagnifyGlassMobile } from 'src/components/icons/MagnifyGalssMobile'
import SearchInput from 'src/components/search/SearchInput'
import ButtonSignIn from 'src/components/ui/Button/ButtonSignIn'
import SlideOver from 'src/components/ui/SlideOver'
import { mark } from 'src/sdk/tests/mark'
import './navbar.scss'

import { Menu } from '../Menu'
import MainModal from '../MenuMobile/MainModal/MainModal'

function Navbar() {
  const [showMenu, setShowMenu] = useState(false)

  const handleCloseSlideOver = () => {
    setShowMenu(false)
  }

  return (
    <>
      <header className="flex flex-col bg-yellow">
        <section className="flex items-center justify-between 3xl:justify-center sm:gap-12 md:gap-6 xl:gap-14 2xl:gap-24 px-4 2xl:px-12 py-2 w-full h-fit xl:max-w-[1440px] mx-auto">
          <div className="bg-yellow block 2xl:hidden">
            <button
              className="bg-yellow"
              data-testid="hamburger-menu"
              aria-label="Open Menu"
              onClick={() => setShowMenu(true)}
            >
              <HamburgerMenu />
            </button>
          </div>
          <a
            href="/"
            aria-label="Go to Arcaplanet home"
            title="Go to Faststore home"
            className="bg-yellow"
          >
            <ArcaplanetLogoBrand className="h-10 w-36 xl:w-[220px] xl:h-[70px]" />
          </a>
          <div className="hidden z-[3] w-full md:block">
            <SearchInput icon={<Ellipse />} />
          </div>
          <div className="flex gap-3 md:gap-6 items-center bg-yellow">
            <ButtonSignIn />
            {/* <UILink as="div" data-fs-button-wishlist aria-label="Open Wishlist">
              <a href={`${storeConfig.accountUrl}#/wishlist`}>
                <WishListNewUpdated width={32} height={32} />
              </a>
            </UILink> */}
            <CartToggle />
          </div>
        </section>
        <div className="hidden 2xl:block">
          <Menu />
        </div>
        <SlideOver
          isOpen={showMenu}
          onDismiss={handleCloseSlideOver}
          size="full"
          direction="leftSide"
          className="modal__mobile tsticky top-12 z-50"
        >
          <MainModal closeMainModal={handleCloseSlideOver} />
        </SlideOver>
      </header>
      <div className="bg-yellow border-[px] border-yellow h-16 py-2 sm:px-4 md:px-0 sticky text-center z-[3] border-solid top-0 md:hidden">
        <SearchInput
          testId="store-input-mobile"
          data-testid="store-input-mobile-button"
          icon={<MagnifyGlassMobile />}
        />
      </div>
    </>
  )
}

Navbar.displayName = 'Navbar'

export default mark(Navbar)
