import { useSession } from '@faststore/sdk'
import { UpdatedUserNew } from 'src/components/icons/UpdatedUserNew'
import { RenderClient } from 'src/components/RenderClient'
import { NavbarButtonsSkeleton } from 'src/components/skeletons/NavbarButtonsSkeleton/NavbarButtonsSkeleton'

const ButtonSignIn = () => {
  const { person } = useSession()

  return (
    <RenderClient skeleton={<NavbarButtonsSkeleton />}>
      <a
        href={person?.id ? '/account' : '/login'}
        className="flex items-center justify-between gap-2 bg-yellow no-underline"
        data-testid="account-button"
      >
        <UpdatedUserNew
          width={28}
          height={32}
          logged={person?.id != null}
          className="w-5 h-6 md:h-[32px] md:w-[28px]"
        />
        <span className="hidden xl:block text-xs font-medium text-nowrap">
          {person?.id ? person.givenName : 'Accedi o Registrati'}
        </span>
      </a>
    </RenderClient>
  )
}

export default ButtonSignIn
