import { useState } from 'react'
import TrustPilotWidget from 'src/components/ui/TrustPilotWidget/TrustPilotWidget'
import widgetSettings from 'src/components/ui/TrustPilotWidget/widgetSettings'
// const TrustPilotWidget = React.lazy(
//   () => import('src/components/ui/TrustPilotWidget/TrustPilotWidget')
// )

type ReviewsProps = {
  gtin: string
  productName: string
}

function ReviewsPDP({ gtin, productName }: ReviewsProps) {
  const [openDescriptionSidebarToggle, setOpenDescriptionSidebarToggle] =
    useState(true)

  const handleOpenSidebar = () => {
    setOpenDescriptionSidebarToggle(!openDescriptionSidebarToggle)
  }

  return (
    <div
      id="recensioni"
      className="w-full max-w-[1520px] mx-auto mt-6 md:mt-12"
    >
      <div className="pb-6 md:pb-10">
        <div className="">
          <div
            className={`px-4 md:px-10 font-normal flex justify-between items-center text-[#14181F] select-none cursor-pointer ${
              openDescriptionSidebarToggle ? 'sidenav-open' : ''
            }`}
            data-collapse-toggle=""
            onClick={handleOpenSidebar}
          >
            <h2 className="text-base md:text-xl">Recensioni</h2>
            <span className="arca-ico-chevron-down block text-2xl icon-toggler" />
          </div>
          <div className="content-collapse">
            <div className="mt-4 md:mt-6">
              {/* <Suspense fallback={<div />}>
                <RenderClient> */}
              <div className="px-4 md:px-10 flex items-center my-2 md:mt-0 md:mb-5 gap-2">
                <TrustPilotWidget
                  widgetSettings={widgetSettings.standard}
                  gtin={gtin}
                  productName={productName}
                />
              </div>
              {/* </RenderClient>
              </Suspense> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReviewsPDP
