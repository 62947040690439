import { lazy, Suspense, useState } from 'react'
import { createPortal } from 'react-dom'
import InfoIcon from 'src/components/icons/InfoIcon'
import ReturnsIcon from 'src/components/icons/ReturnsIcon'
import { blockOutsideScroll } from './utilities'

const ModalContainer = lazy(() => import('./ModalContainer'))
const ReturnsDetailsModal = lazy(() => import('./ReturnsDetailsModal'))

const ReturnsLabel = () => {
  const [modalOpen, setModalOpen] = useState<'open' | 'close'>('close')

  const handleModal = (action: 'open' | 'close') => {
    blockOutsideScroll(action === 'open')
    setModalOpen(action)
  }

  return (
    <div className="flex gap-1">
      <span className="flex items-center justify-center">
        <ReturnsIcon width={18} height={18} />
      </span>
      <span className="text-black font-medium text-sm">
        Resi e rimborsi{' '}
        <span className="inline-flex align-text-top ml-[1px]">
          <button
            onClick={() => handleModal('open')}
            className="cursor-pointer bg-transparent flex items-center justify-center p-0"
          >
            <InfoIcon />
          </button>
        </span>
      </span>
      {modalOpen === 'open' &&
        createPortal(
          <Suspense fallback={null}>
            <ModalContainer
              headerTitle="Resi e rimborsi"
              closeModal={() => handleModal('close')}
            >
              <ReturnsDetailsModal />
            </ModalContainer>
          </Suspense>,
          document.body
        )}
    </div>
  )
}

export default ReturnsLabel
