import type { IconProps } from './IconsTypes'

export const CartIcon = ({ className, width = 24, height = 28 }: IconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M8.27002 19C8.27002 19.69 7.71002 20.25 7.02002 20.25C6.33102 20.25 5.76501 19.69 5.76501 19C5.76501 18.31 6.32001 17.75 7.01001 17.75H7.02002C7.71002 17.75 8.27002 18.31 8.27002 19ZM15.02 17.75H15.01C14.32 17.75 13.765 18.31 13.765 19C13.765 19.69 14.33 20.25 15.02 20.25C15.71 20.25 16.27 19.69 16.27 19C16.27 18.31 15.71 17.75 15.02 17.75ZM19.705 6.492L18.691 12.658C18.428 14.104 17.774 15.75 15 15.75H6.73401C5.37501 15.75 4.20396 14.735 4.01196 13.389L2.50195 2.82401C2.41395 2.21201 1.88301 1.75101 1.26501 1.75101H1C0.586 1.75101 0.25 1.41501 0.25 1.00101C0.25 0.587007 0.586 0.251007 1 0.251007H1.26599C2.62499 0.251007 3.79604 1.266 3.98804 2.612L4.07996 3.25101H17C17.818 3.25101 18.5881 3.611 19.1121 4.239C19.6351 4.866 19.852 5.688 19.705 6.492ZM17.213 12.403L17.3199 11.75H11C10.586 11.75 10.25 11.414 10.25 11C10.25 10.586 10.586 10.25 11 10.25H17.567L18.2271 6.23599C18.2961 5.85799 18.198 5.484 17.959 5.19901C17.72 4.91401 17.371 4.75 16.999 4.75H4.29297L5.49695 13.177C5.58495 13.789 6.11601 14.25 6.73401 14.25H15C16.597 14.25 16.986 13.654 17.213 12.403Z"
      fill="black"
    />
  </svg>
)
