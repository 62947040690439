import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect } from 'react'
import { useScrollEvent } from 'src/sdk/analytics/hooks/useScrollEvent'

import storeConfig from '../../store.config'
import { RenderClient } from 'src/components/RenderClient'

function Page() {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.location.href = `${storeConfig.loginUrl}?returnUrl=/account`
    }

    window.addEventListener('scroll', useScrollEvent)

    return () => {
      window.removeEventListener('scroll', useScrollEvent)
    }
  }, [])

  return (
    <RenderClient>
      <GatsbySeo />

      <div>loading...</div>
    </RenderClient>
  )
}

export default Page
